export const MENU_ATLAS = [
  {
    path: '/atlas/zheleznaya-doroga',
    name: 'Сооружение железных дорог',
    nameEn: 'Construction of railways',
  },
  {
    path: '/atlas/podgotovka-kadrov',
    name: 'Подготовка кадров для отрасли',
    nameEn: 'Personnel training for the industry',
  },
  {
    path: '/atlas/gruzovie-perevozki',
    name: 'Грузовые перевозки. Общие сведения',
    nameEn: 'Freight transportation. General information',
  },
  {
    path: '/atlas/poligonnyh-principy',
    name: 'Полигонные принципы управления',
    nameEn: 'Territorial management principles',
  },
  {
    path: '/atlas/klimat-rossii',
    name: 'Климат России',
    nameEn: 'Climate of Russia',
  },
  {
    path: '/atlas/passazhirskie-perevozki',
    name: 'Пассажирские перевозки',
    nameEn: 'Passenger transportation',
  },
  {
    path: '/atlas/toplivno-energ-resursy',
    name: 'Топливно-энергетические ресурсы',
    nameEn: 'Fuel and energy resources',
  },
  {
    path: '/atlas/elektroenergetika',
    name: 'Электроэнергетика',
    nameEn: 'Electric power industry',
  },
  {
    path: '/atlas/mineralnye-resursy',
    name: 'Минеральные ресурсы',
    nameEn: 'Mineral resources',
  },
  {
    path: '/atlas/istoricheskoe-nasledie',
    name: 'Историческое наследие',
    nameEn: 'Historical heritage',
  },
  {
    path: '/atlas/snezhnyj-pokrov-chislo-dnei',
    name: 'Снежный покров. Метели',
    nameEn: 'Snow cover. Snowstorms',
    selector: 'snezhnyj',
    subs: [
      {
        path: '/atlas/snezhnyj-pokrov-chislo-dnei',
        name: 'Число дней со снежным покровом',
        nameEn: 'Number of days with snow cover',
      },
      {
        path: '/atlas/snezhnyj-pokrov-visota',
        name: 'Высота снежного покрова',
        nameEn: 'Snow cover height',
      },
      {
        path: '/atlas/snezhnyj-pokrov-otlojenia',
        name: 'Гололёдно-изморозевые отложения',
        nameEn: 'Glaze and frost deposits',
      },
      {
        path: '/atlas/snezhnyj-pokrov-meteli',
        name: 'Метели',
        nameEn: 'Snowstorms',
      },
    ],
  },
  {
    path: '/atlas/temperatury-sred-vozduha',
    name: 'Температуры',
    nameEn: 'Temperatures',
    selector: 'temperatury',
    subs: [
      {
        path: '/atlas/temperatury-sred-vozduha',
        name: 'Среднегодовая температура воздуха',
        nameEn: 'Average annual air temperature',
      },
      {
        path: '/atlas/temperatury-min-sred-vozduha',
        name: 'Минимальная среднегодовая температура воздуха',
        nameEn: 'Minimum average annual air temperature',
      },
      {
        path: '/atlas/temperatury-max-sred-vozduha',
        name: 'Максимальная среднегодовая температура воздуха',
        nameEn: 'Maximum average annual air temperature',
      },
      {
        path: '/atlas/temperatury-sred-menee-8',
        name: 'Число дней со среднесуточной температурой воздуха менее +8°С',
        nameEn: 'Number of days with an average daily air temperature below +8°C',
      },
    ],
  },
  {
    path: '/atlas/elektrifikaciya',
    name: 'Электрификация и электроснабжение',
    nameEn: 'Electrification and power supply',
  },
  {
    path: '/atlas/putevoe-hozyajstvo',
    name: 'Путь и путевое хозяйство',
    nameEn: 'Track and track facilities',
  },
  {
    path: '/atlas/proizvodstvo-produkcii',
    name: 'Производство продукции для железных дорог',
    nameEn: 'Production for railways',
  },
  {
    path: '/atlas/prigorodnoe-passazh-soobshchenie',
    name: 'Пригородное и межобластное пассажирское сообщение',
    nameEn: 'Suburban and interregional passenger transportation',
  },
]

import { Card, CardProps, cnCard } from '@/blocks/card'
import style from './card_default.module.sass'
import { styled } from '@/utils/block'

styled(style)

export type CardDefaultProps = {
  angular?: boolean
} & CardProps

export function CardDefault({
  children,
  className,
  angular,
  ...rest
}: CardDefaultProps): JSX.Element {
  return (
    <Card {...rest} className={cnCard({ default: true, angular }, [className])}>
      {children}
    </Card>
  )
}

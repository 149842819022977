import { cn } from '@/utils/block'
import { CSSProperties, ReactNode } from 'react'

export const cnCard = cn('card')

export type CardProps = {
  children?: ReactNode
  className?: string
  style?: CSSProperties
}

export function Card({ children, className, ...rest }: CardProps): JSX.Element {
  return (
    <div {...rest} className={cnCard({}, [className])}>
      {children}
    </div>
  )
}

import type { Map } from 'maplibre-gl'

export const isMapLoaded = (map: Map | undefined): boolean => {
  // TODO: _loaded вроде бы работает, но он непубличный
  // TODO: https://github.com/mapbox/mapbox-gl-js/issues/6707
  if (!map) return false
  return map?._loaded as boolean
}

export const getVectorStyle = (theme: 'dark' | 'light', lang?: string) => {
  return theme === 'dark'
    ? `${process.env.REACT_APP_BASE_MAP_STYLE_URL}/styles/dark_${lang}/style.json`
    : `${process.env.REACT_APP_BASE_MAP_STYLE_URL}/styles/white_${lang}/style.json`
}

export const getVectorStyleSupply = (theme: 'dark' | 'light', lang?: string) => {
  return theme === 'dark'
    ? `${process.env.REACT_APP_BASE_MAP_STYLE_URL}/styles/dark_service/style.json`
    : `${process.env.REACT_APP_BASE_MAP_STYLE_URL}/styles/white_service/style.json`
}

export const getVectorStyleAtlas = (theme: 'dark' | 'light', lang?: string) => {
  return theme === 'dark'
    ? `${process.env.REACT_APP_BASE_MAP_STYLE_URL}/styles/dark_atlas_${lang}/style.json`
    : `${process.env.REACT_APP_BASE_MAP_STYLE_URL}/styles/white_atlas_${lang}/style.json`
}

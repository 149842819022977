import { cnCard } from '@/blocks/card'
import { CSSProperties, ReactNode } from 'react'
import style from './card__text.module.sass'
import { styled } from '@/utils/block'

styled(style)

type CardTextProps = {
  children?: ReactNode
  second?: boolean
  style?: CSSProperties
}

export function CardText({ children, second, ...rest }: CardTextProps): JSX.Element {
  return (
    <div {...rest} className={cnCard('text', { second })}>
      {children}
    </div>
  )
}

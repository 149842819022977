import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router'
import { useEffect } from 'react'
import { Outlet } from 'react-router-dom'

export function LanguagePath() {
  const { i18n } = useTranslation()
  const { lang } = useParams()
  const navigate = useNavigate()
  const curPath = window.location.pathname
  const languages = i18n.options.supportedLngs

  useEffect(() => {
    if (!Array.isArray(languages)) return

    if (lang && i18n.resolvedLanguage !== lang) {
      if (languages?.includes(lang)) {
        i18n.changeLanguage(lang)
      } else {
        navigate('/' + i18n.resolvedLanguage + curPath, { replace: true })
      }
    }
  }, [lang])

  return <Outlet />
}
